import ApiService from '@/core/services/api.service';
import JwtService from '@/core/services/jwt.service';
import SaveDataUtil from '@/utils/saveDataToLocal';

// action types
export const VERIFY_AUTH = 'verifyAuth';
export const LOGIN = 'login';
export const LOGOUT = 'logout';
export const REGISTER = 'register';
export const UPDATE_USER = 'updateUser';

// mutation types
export const PURGE_AUTH = 'logOut';
export const SET_AUTH = 'setUser';
export const SET_ERROR = 'setError';

const state = {
  errors: null,
  user: {},
  isAuthenticated: !!JwtService.getToken()
};

const getters = {
  currentUser(state) {
    return state.user;
  },
  isAuthenticated(state) {
    return state.isAuthenticated;
  }
};

const actions = {
  [LOGIN](context, credentials) {
    return new Promise((resolve, reject) => {
      ApiService.post('login', credentials)
        .then(({ data }) => {
          if (data.status === 1) {
            context.commit(SET_AUTH, data.data);
            resolve(data.data);
          } else {
            setTimeout(() => {
              context.commit(SET_ERROR, data.message);
              reject(data.message);
            }, 500);
          }
        })
        .catch(({ response }) => {
          setTimeout(() => {
            if (response) {
              context.commit(SET_ERROR, response.data.message);
            } else {
              context.commit(SET_ERROR, 'Kiểm tra lại kết nối mạng');
            }
            reject();
          }, 500);
        });
    });
  },
  [LOGOUT](context) {
    context.commit(PURGE_AUTH);
  },
  [REGISTER](context, credentials) {
    return new Promise((resolve, reject) => {
      ApiService.post('users', { user: credentials })
        .then(({ data }) => {
          context.commit(SET_AUTH, data);
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);
          reject(response);
        });
    });
  },
  [VERIFY_AUTH](context) {
    if (JwtService.getToken()) {
      // ApiService.setHeader();
      // ApiService.get("verify")
      //   .then(({ data }) => {
      //     context.commit(SET_AUTH, data);
      //   })
      //   .catch(({ response }) => {
      //     context.commit(SET_ERROR, response.data.errors);
      //   });
    } else {
      context.commit(PURGE_AUTH);
    }
  },
  [UPDATE_USER](context, payload) {
    const { email, username, password, image, bio } = payload;
    const user = { email, username, bio, image };
    if (password) {
      user.password = password;
    }

    return ApiService.put('user', user).then(({ data }) => {
      context.commit(SET_AUTH, data);
      return data;
    });
  }
};

const mutations = {
  [SET_ERROR](state, error) {
    state.errors = error;
  },
  [SET_AUTH](state, user) {
    state.isAuthenticated = true;
    state.user = user;
    state.errors = {};
    JwtService.saveToken(state.user.accessToken);
    SaveDataUtil.saveData('listFeature', state.user.listFeature);
    SaveDataUtil.saveData('employee_info', JSON.stringify(state.user.employee));
  },
  [PURGE_AUTH](state) {
    state.isAuthenticated = false;
    state.user = {};
    state.errors = {};
    JwtService.destroyToken();
    SaveDataUtil.destroyData('listFeature');
    SaveDataUtil.destroyData('employee_info');
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
