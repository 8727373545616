// action types
export const HANDLE_SET_ORDER_INFO = 'handleSetOrderInfo';
export const HANDLE_PURGE_ORDER_INFO = 'handleRemoveOrderInfo';

// mutation types
export const SET_ORDER_INFO = 'setOrderInfo';
export const PURGE_ORDER_INFO = 'removeOrderInfo';

const orderInfoBase = {
  orderId: null,
  preOrderId: null,
  storeId: null,
  storeName: null,
  orderSourceId: null,
  orderSourceName: null,
  orderType: null,
  orderTypeName: null,
  status: null,
  statusName: null,
  cancelStatus: null,
  cancelStatusName: null,
  partnerPaymentStatus: null,
  partnerPaymentStatusName: null,
  checkDate: null,
  checkTime: null,
  codeShip: null,
  saleStoreId: null,
  technicalId: null
};

export default {
  state: {
    orderInfo: {
      ...orderInfoBase
    }
  },
  getters: {
    /**
     * Get order info
     * @param state
     * @returns {*}
     */
    getOrderInfo(state) {
      return state.orderInfo;
    }
  },
  actions: {
    [HANDLE_SET_ORDER_INFO](state, payload) {
      state.commit(SET_ORDER_INFO, payload);
    },
    [HANDLE_PURGE_ORDER_INFO](state) {
      state.commit(PURGE_ORDER_INFO);
    }
  },
  mutations: {
    [SET_ORDER_INFO](state, payload) {
      state.orderInfo = payload;
    },
    [PURGE_ORDER_INFO](state) {
      state.orderInfo = { ...orderInfoBase };
    }
  }
};
